import { API_NOT_FOUND } from '../actions'

export class ApiError extends Error {
  constructor(action, ...error) {
    super(...error)

    this.action = action
  }

  static handleError(error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 404:
          throw new ApiError({ type: API_NOT_FOUND }, error)
        default:
          throw error
      }
    }

    if (error?.response?.data) {
      if (error.response.data?.response?.error) {
        throw new Error(error.response.data.response.error)
      }

      if (error.response.data?.error) {
        throw new Error(error.response.data.error)
      }

      if (error.response.data?.message) {
        throw new Error(error.response.data.message)
      }

      throw new Error(JSON.stringify(error.response.data))
    }

    throw new Error(error)
  }
}
