export const FAILED_CREATE_CAMPAIGN = 'FAILED_CREATE_CAMPAIGN'
export const FAILED_DELETE_CAMPAIGN = 'FAILED_DELETE_CAMPAIGN'
export const FAILED_DUPLICATE_CAMPAIGN = 'FAILED_DUPLICATE_CAMPAIGN'
export const FAILED_GET_AUTOMATION_FLOWS = 'FAILED_GET_AUTOMATION_FLOWS'
export const FAILED_GET_CAMPAIGN = 'FAILED_GET_CAMPAIGN'
export const FAILED_GET_CAMPAIGNS = 'FAILED_GET_CAMPAIGNS'
export const FAILED_GET_OUTREACHES = 'FAILED_GET_OUTREACHES'
export const FAILED_PUBLISH_CAMPAIGN = 'FAILED_PUBLISH_CAMPAIGN'
export const FAILED_SET_CAMPAIGN_FLOW = 'FAILED_SET_CAMPAIGN_FLOW'
export const FAILED_TOGGLE_PAUSE_CAMPAIGN = 'FAILED_TOGGLE_PAUSE_CAMPAIGN'
export const FAILED_UPDATE_CAMPAIGN = 'FAILED_UPDATE_CAMPAIGN'
export const RECEIVE_CREATE_CAMPAIGN = 'RECEIVE_CREATE_CAMPAIGN'
export const RECEIVE_DELETE_CAMPAIGN = 'RECEIVE_DELETE_CAMPAIGN'
export const RECEIVE_DUPLICATE_CAMPAIGN = 'RECEIVE_DUPLICATE_CAMPAIGN'
export const RECEIVE_GET_ARCHIVED_RESPONSES_COUNT = 'RECEIVE_GET_ARCHIVED_RESPONSES_COUNT'
export const RECEIVE_GET_AUTOMATION_FLOWS = 'RECEIVE_GET_AUTOMATION_FLOWS'
export const RECEIVE_GET_CAMPAIGN = 'RECEIVE_GET_CAMPAIGN'
export const RECEIVE_GET_CAMPAIGNS = 'RECEIVE_GET_CAMPAIGNS'
export const RECEIVE_GET_OUTREACHES = 'RECEIVE_GET_OUTREACHES'
export const RECEIVE_GET_UNREAD_RESPONSES_COUNT = 'RECEIVE_GET_UNREAD_RESPONSES_COUNT'
export const RECEIVE_PUBLISH_CAMPAIGN = 'RECEIVE_PUBLISH_CAMPAIGN'
export const RECEIVE_SET_CAMPAIGN_FLOW = 'RECEIVE_SET_CAMPAIGN_FLOW'
export const RECEIVE_TOGGLE_PAUSE_CAMPAIGN = 'RECEIVE_TOGGLE_PAUSE_CAMPAIGN'
export const RECEIVE_UPDATE_CAMPAIGN = 'RECEIVE_UPDATE_CAMPAIGN'
export const REQUEST_CREATE_CAMPAIGN = 'REQUEST_CREATE_CAMPAIGN'
export const REQUEST_DELETE_CAMPAIGN = 'REQUEST_DELETE_CAMPAIGN'
export const REQUEST_DUPLICATE_CAMPAIGN = 'REQUEST_DUPLICATE_CAMPAIGN'
export const REQUEST_GET_AUTOMATION_FLOWS = 'REQUEST_GET_AUTOMATION_FLOWS'
export const REQUEST_GET_CAMPAIGN = 'REQUEST_GET_CAMPAIGN'
export const REQUEST_GET_CAMPAIGNS = 'REQUEST_GET_CAMPAIGNS'
export const REQUEST_GET_OUTREACHES = 'REQUEST_GET_OUTREACHES'
export const REQUEST_PUBLISH_CAMPAIGN = 'REQUEST_PUBLISH_CAMPAIGN'
export const REQUEST_SET_CAMPAIGN_FLOW = 'REQUEST_SET_CAMPAIGN_FLOW'
export const REQUEST_TOGGLE_PAUSE_CAMPAIGN = 'REQUEST_TOGGLE_PAUSE_CAMPAIGN'
export const REQUEST_UPDATE_CAMPAIGN = 'REQUEST_UPDATE_CAMPAIGN'
