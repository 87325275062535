import * as actions from 'redux/actions/inbox'
import { RESET_STATE } from 'redux/actions/global'
import { supportedRoles } from 'config/user'

const initialState = {
  member: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false,
  },
  collection: {
    data: [],
    error: '',
    fetching: true,
  },
  archivedCount: 0,
  unreadCount: 0,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_GET_INBOX:
      return {
        ...state,
        collection: {
          data: action.data,
          error: '',
          fetching: false,
        }
      }
    case actions.RECEIVE_GET_ARCHIVED_COUNT:
      return {
        ...state,
        archivedCount: action?.count || 0
      }
    case actions.RECEIVE_GET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action?.count || 0
      }
    default:
      return state
  }
}
