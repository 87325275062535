import React, { useEffect } from 'react'
import { inputs } from '@akkeramsterdam/whatson-components'
import ExtendedRadio from 'ui/components/ExtendedRadio'
import SimpleCampaign from './simple.svg'
import AutomatedCampaign from './automated.svg'

const { Input } = inputs

export const types = {
  TYPE_SIMPLE: 'simple',
  TYPE_AUTOMATED: 'automated',
}

export default ({ state, setState, setIsValid }) => {
  useEffect(() => {
    if (state.name && state.type) {
      setIsValid(true)
    }
  }, [setIsValid, state])

  return (
    <>
      <Input
        label="Internal campaign name"
        onChange={(e) => setState({ name: e.target.value })}
        value={state.name}
      />

      <ExtendedRadio
        label="Campaign type"
        onChange={(type) => setState({ type })}
        options={[
          {
            description: 'Create a campaign with only one email sent to your audience.',
            disabled: true,
            image: SimpleCampaign,
            icon: "HiPencil",
            label: 'One-email campaign',
            value: types.TYPE_SIMPLE,
          },
          {
            description: 'Create a campaign flow with multiple email and triggers.',
            image: AutomatedCampaign,
            label: 'Automated campaign',
            value: types.TYPE_AUTOMATED,
          }
        ]}
        value={state.type}
      />
    </>
  )
}
