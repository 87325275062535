export const FAILED_GET_MERGE_FIELDS = 'FAILED_GET_MERGE_FIELDS'
export const FAILED_GET_SENDERS = 'FAILED_GET_SENDERS'
export const FAILED_UPLOAD_IMAGE = 'FAILED_UPLOAD_IMAGE'
export const FAILED_UPLOAD_BLOCKLIST_CSV = 'FAILED_UPLOAD_BLOCKLIST_CSV'
export const RECEIVE_GET_MERGE_FIELDS = 'RECEIVE_GET_MERGE_FIELDS'
export const RECEIVE_GET_SENDERS = 'RECEIVE_GET_SENDERS'
export const RECEIVE_UPLOAD_IMAGE = 'RECEIVE_UPLOAD_IMAGE'
export const RECEIVE_UPLOAD_BLOCKLIST_CSV = 'RECEIVE_UPLOAD_BLOCKLIST_CSV'
export const REQUEST_GET_MERGE_FIELDS = 'REQUEST_GET_MERGE_FIELDS'
export const REQUEST_GET_SENDERS = 'REQUEST_GET_SENDERS'
export const REQUEST_UPLOAD_IMAGE = 'REQUEST_UPLOAD_IMAGE'
export const REQUEST_SEND_TEST_EMAIL = 'REQUEST_SEND_TEST_EMAIL'
export const RECEIVE_SEND_TEST_EMAIL = 'RECEIVE_SEND_TEST_EMAIL'
export const FAILED_SEND_TEST_EMAIL = 'FAILED_SEND_TEST_EMAIL'
export const REQUEST_UPLOAD_BLOCKLIST_CSV = 'REQUEST_UPLOAD_BLOCKLIST_CSV'
