import React from 'react'
import classNames from 'classnames'
import { inputs } from '@akkeramsterdam/whatson-components'
import styles from './InputAddon.module.scss'

const { Input } = inputs

export default ({
  addon,
  position = 'before',
  className,
  wrapperClassname,
  ...rest
} : {
  addon: string
  position: 'before' | 'after',
  className: string
  wrapperClassname: string
}) => (
  <div className={classNames(styles.InputAddon, wrapperClassname)}>
    {addon && position === 'before' &&
    <div className={classNames(styles.Addon, className)}>
      {addon}
    </div>
    }

    <Input className={classNames(styles.Input, className)} {...rest} />

    {addon && position === 'after' &&
    <div className={classNames(styles.Addon, className)}>
      {addon}
    </div>
    }
  </div>
)
