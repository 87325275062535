import { call, delay, takeLatest, put } from 'redux-saga/effects'
import api from 'services/api'
import { InsightsAdapter } from 'adapters/insights'
import * as actions from 'redux/actions/insights'
import { getCurrentOrganisation } from 'config/helpers'

const insightsAdapter = new InsightsAdapter()

function* getCampaignEmailAnalytics(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/analytics/emails`, {
      campaign_id: action.campaignId,
      end: action.endDate,
      organisation_id: organisation.id,
      start: action.startDate,
    })

    yield put({
      type: actions.RECEIVE_GET_CAMPAIGN_EMAIL_ANALYTICS,
      data: Object.values(insightsAdapter.fromApi('', [data])[0]),
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_CAMPAIGN_EMAIL_ANALYTICS })
  }
}

function* getCampaignOverviewInsights(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(
      api.post,
      `/campaign/overview?organisation_id=${organisation.id}${
        action.pageNumber ? `&page=${action.pageNumber}` : ''
      }${action.search ? `&name=${action.search}` : ''}${
        !!action.statusFilter && action.statusFilter?.toLowerCase() !== 'all'
          ? `&status=${action.statusFilter.toLowerCase()}`
          : ''
      }`,
      {
        campaign_id: action.campaignId,
        end: action.endDate,
        organisation_id: organisation.id,
        start: action.startDate,
      }
    )

    yield put({
      type: actions.RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS,
      data: insightsAdapter.fromApi('', data?.data),
      pageNumber: action.pageNumber,
      totalCount: data?.total_count,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_CAMPAIGN_OVERVIEW_INSIGHTS })
  }
}

function* getCampaignOverviewInsightsCount(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.get, `/campaign/counts?organisation_id=${organisation.id}`, {
      campaign_id: action.campaignId,
      end: action.endDate,
      organisation_id: organisation.id,
      start: action.startDate,
    })

    yield put({
      type: actions.RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT,
      data: insightsAdapter.fromApi('', data),
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_CAMPAIGN_OVERVIEW_INSIGHTS })
  }
}

function* getCampaignInsights(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/analytics/compare`, {
      campaign_ids: action.campaignIds,
      end: action.endDate,
      organisation_id: organisation.id,
      start: action.startDate,
      to_compare: action.toCompare,
    })

    yield put({
      type: actions.RECEIVE_GET_CAMPAIGN_INSIGHTS,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_CAMPAIGN_INSIGHTS })
  }
}

function* getGeneralAnalytics(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/analytics/general`, {
      campaign_id: action.campaignId,
      end: action.endDate,
      organisation_id: organisation.id,
      start: action.startDate,
    })

    yield put({
      type: actions.RECEIVE_GET_GENERAL_ANALYTICS,
      data: insightsAdapter.fromApi('', [data])[0],
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_GENERAL_ANALYTICS })
  }
}

function* getOrganisationInsights(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/analytics`, {
      end: action.endDate,
      organisation_id: organisation.id,
      start: action.startDate,
    })

    yield put({
      type: actions.RECEIVE_GET_ORGANISATION_INSIGHTS,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_ORGANISATION_INSIGHTS })
  }
}

export default function* root() {
  yield takeLatest(actions.REQUEST_GET_CAMPAIGN_EMAIL_ANALYTICS, getCampaignEmailAnalytics)
  yield takeLatest(actions.REQUEST_GET_CAMPAIGN_INSIGHTS, getCampaignInsights)
  yield takeLatest(actions.REQUEST_GET_CAMPAIGN_OVERVIEW_INSIGHTS, getCampaignOverviewInsights)
  yield takeLatest(
    actions.REQUEST_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT,
    getCampaignOverviewInsightsCount
  )

  yield takeLatest(actions.REQUEST_GET_GENERAL_ANALYTICS, getGeneralAnalytics)
  yield takeLatest(actions.REQUEST_GET_ORGANISATION_INSIGHTS, getOrganisationInsights)
}
