import * as actions from 'redux/actions/campaign'
import { RESET_STATE } from 'redux/actions/global'
import { supportedRoles } from 'config/user'

const initialState = {
  campaign: {
    collection: {
      data: [],
      error: '',
      fetching: true,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
      togglingPause: false,
      publishing: false,
      saving: false,
    },
  },
  flow: {
    collection: {
      data: [],
      error: '',
      fetching: true,
    },
  },
  outreach: {
    collection: {
      data: [],
      error: '',
      fetching: false,
    },
  },
  responses: {
    unreadCount: 0,
    archivedCount: 0,
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_GET_ARCHIVED_RESPONSES_COUNT:
      return {
        ...state,
        responses: {
          ...state.responses,
          archivedCount: action.count,
        }
      }
    case actions.RECEIVE_GET_UNREAD_RESPONSES_COUNT:
      return {
        ...state,
        responses: {
          ...state.responses,
          unreadCount: action.count,
        }
      }
    case actions.RECEIVE_GET_AUTOMATION_FLOWS:
      return {
        ...state,
        flow: {
          ...state.flow,
          collection: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_CAMPAIGNS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          collection: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_OUTREACHES:
      return {
        ...state,
        outreach: {
          ...state.outreach,
          collection: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_SET_CAMPAIGN_FLOW:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            saving: false,
          },
        },
      }
    case actions.RECEIVE_PUBLISH_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            publishing: false,
          },
        },
      }
    case actions.RECEIVE_TOGGLE_PAUSE_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            togglingPause: false,
          },
        },
      }
    case actions.REQUEST_SET_CAMPAIGN_FLOW:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            saving: true,
          },
        },
      }
    case actions.REQUEST_PUBLISH_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            publishing: true,
          },
        },
      }
    case actions.REQUEST_TOGGLE_PAUSE_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          member: {
            ...state.campaign.member,
            togglingPause: true,
          },
        },
      }

    case RESET_STATE:
      if (action.state === 'campaign') {
        return {
          ...state,
          [action.screen]: initialState[action.screen],
        }
      }

    default:
      return state
  }
}
