export const FAILED_GET_CAMPAIGN_EMAIL_ANALYTICS = 'FAILED_GET_CAMPAIGN_EMAIL_ANALYTICS'
export const FAILED_GET_CAMPAIGN_INSIGHTS = 'FAILED_GET_CAMPAIGN_INSIGHTS'
export const FAILED_GET_CAMPAIGN_OVERVIEW_INSIGHTS = 'FAILED_GET_CAMPAIGN_OVERVIEW_INSIGHTS'
export const FAILED_GET_GENERAL_ANALYTICS = 'FAILED_GET_GENERAL_ANALYTICS'
export const FAILED_GET_ORGANISATION_INSIGHTS = 'FAILED_GET_ORGANISATION_INSIGHTS'
export const RECEIVE_GET_CAMPAIGN_EMAIL_ANALYTICS = 'RECEIVE_GET_CAMPAIGN_EMAIL_ANALYTICS'
export const RECEIVE_GET_CAMPAIGN_INSIGHTS = 'RECEIVE_GET_CAMPAIGN_INSIGHTS'
export const RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS = 'RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS'
export const RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT =
  'RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT'
export const RECEIVE_GET_GENERAL_ANALYTICS = 'RECEIVE_GET_GENERAL_ANALYTICS'
export const RECEIVE_GET_ORGANISATION_INSIGHTS = 'RECEIVE_GET_ORGANISATION_INSIGHTS'
export const REQUEST_GET_CAMPAIGN_EMAIL_ANALYTICS = 'REQUEST_GET_CAMPAIGN_EMAIL_ANALYTICS'
export const REQUEST_GET_CAMPAIGN_INSIGHTS = 'REQUEST_GET_CAMPAIGN_INSIGHTS'
export const REQUEST_GET_CAMPAIGN_OVERVIEW_INSIGHTS = 'REQUEST_GET_CAMPAIGN_OVERVIEW_INSIGHTS'
export const REQUEST_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT =
  'REQUEST_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT'
export const REQUEST_GET_GENERAL_ANALYTICS = 'REQUEST_GET_GENERAL_ANALYTICS'
export const REQUEST_GET_ORGANISATION_INSIGHTS = 'REQUEST_GET_ORGANISATION_INSIGHTS'
