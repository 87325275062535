import { BaseApiAdapter } from 'services/api/adapter'
import { adaptEmailFromApi, adaptEmailToApi } from './helpers'

const getTemplate = (template) => adaptEmailFromApi(template)

const getTemplates = (templates) => templates.map((template) => adaptEmailFromApi(template))

const persistTemplate = ({ id, isWhatsonTemplate, ...template }) =>
  adaptEmailToApi({
    ...template,
    ...(!isWhatsonTemplate ? { id } : {}),
  })

export class ContentAdapter extends BaseApiAdapter {
  fromApi = (adapter, theirs) => {
    switch (adapter) {
      case 'getTemplate':
        return getTemplate(super.fromApi('', theirs))
      case 'getTemplates':
        return getTemplates(super.fromApi('', theirs))
      default:
        return super.fromApi('', theirs)
    }
  }

  toApi = (adapter, ours) => {
    switch (adapter) {
      case 'persistTemplate':
        return super.toApi('', persistTemplate(ours))
      default:
        return super.toApi('', ours)
    }
  }
}
