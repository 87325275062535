import * as actions from 'redux/actions/people'

const initialState = {
  audience: {
    fetching: false,
    collection: {
      data: [],
      error: '',
      fetching: true,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
    },
  },
  blockList: {
    collection: {
      data: [],
      error: '',
      fetching: true,
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
    },
  },
  explore: {
    autoComplete: {
      data: {},
      error: '',
      fetching: true,
    },
    filters: {
      data: [],
      error: '',
      fetching: true,
    },
    sample: {
      count: 0,
      data: [],
      error: '',
      fetching: true,
    },
  },
  leads: {
    collection: {
      data: [],
      error: '',
      fetching: true,
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
    },
  },
  prospects: {
    collection: {
      data: [],
      error: '',
      fetching: true,
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
    },
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST_GET_PROSPECT_SAMPLE:
      return {
        ...state,
        explore: {
          ...state.explore,
          sample: {
            ...state.explore.sample,
            error: '',
            fetching: true,
          },
        },
      }
    case actions.FAILED_GET_PROSPECT_SAMPLE:
      return {
        ...state,
        explore: {
          ...state.explore,
          sample: {
            ...state.explore.sample,
            error: 'Could not retrieve prospect sample',
            fetching: false,
          },
        },
      }
    case actions.REQUEST_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS:
      return {
        ...state,
        explore: {
          ...state.explore,
          autoComplete: {
            ...state.explore.autoComplete,
            error: '',
            fetching: true,
          },
        },
      }
    case actions.RECEIVE_GET_AUDIENCE:
      return {
        ...state,
        audience: {
          ...state.audience,
          member: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.REQUEST_GENERATE_AUDIENCE:
      return {
        ...state,
        audience: {
          ...state.audience,
          fetching: true,
        },
      }
    case actions.RECEIVE_GENERATE_AUDIENCE:
      return {
        ...state,
        audience: {
          ...state.audience,
          fetching: false,
        },
      }
    case actions.FAILED_GENERATE_AUDIENCE:
      return {
        ...state,
        audience: {
          ...state.audience,
          fetching: false,
        },
      }
    case actions.RECEIVE_GET_AUDIENCES:
      return {
        ...state,
        audience: {
          ...state.audience,
          collection: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_BLOCK_LIST:
      return {
        ...state,
        blockList: {
          ...state.blockList,
          collection: {
            data: action.data,
            totalCount: action.totalCount,
            pageNumber: action.pageNumber,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_PROSPECT_SAMPLE:
      return {
        ...state,
        explore: {
          ...state.explore,
          sample: {
            count: null,
            data: action.data,
            error: '',
            fetching: false,
            countFetching: true,
          },
        },
      }

    case actions.RECEIVE_GET_PROSPECT_SAMPLE_COUNT:
      return {
        ...state,
        explore: {
          ...state.explore,
          sample: {
            ...state.explore.sample,
            count: action.count,
            countFetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS:
      return {
        ...state,
        explore: {
          ...state.explore,
          autoComplete: {
            data: {
              ...state.explore.autoComplete.data,
              [action.filterId]: {
                [action.category]: {
                  [action.index]: action.data,
                },
              },
            },
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_PROSPECT_SAMPLE_FILTERS:
      return {
        ...state,
        explore: {
          ...state.explore,
          filters: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_PROSPECTS:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          collection: {
            data: action.data,
            totalCount: action.totalCount,
            pageNumber: action.pageNumber,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_LEADS:
      return {
        ...state,
        leads: {
          ...state.leads,
          collection: {
            data: action.data,
            totalCount: action.totalCount,
            pageNumber: action.pageNumber,
            error: '',
            fetching: false,
          },
        },
      }
    default:
      return state
  }
}
