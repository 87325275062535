import { call, takeLatest, put } from 'redux-saga/effects'
import api from 'services/api'
import { ContentAdapter } from 'adapters/content'
import * as contentActions from 'redux/actions/content'
import { getCurrentOrganisation } from 'config/helpers'
import { NOTIFY } from 'redux/actions/notification'

const contentAdapter = new ContentAdapter()

function* getOrganisationTemplates() {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/organisation/content/email/`, {
      organisation_id: organisation.id,
    })

    yield put({
      type: contentActions.RECEIVE_GET_ORGANISATION_TEMPLATES,
      data: contentAdapter.fromApi('getTemplates', data),
    })
  } catch (error) {
    yield put({ type: contentActions.FAILED_GET_ORGANISATION_TEMPLATES })
  }
}

function* updateOrganisationTemplate(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/organisation/content/email/${action.templateId}`, {
      organisation_id: organisation.id,
    })
    const adapted = contentAdapter.fromApi('getTemplate', data)

    yield put({
      type: contentActions.RECEIVE_UPDATE_ORGANISATION_TEMPLATE,
      payload: adapted,
    })
  } catch (error) {
    yield put({ type: contentActions.FAILED_UPDATE_ORGANISATION_TEMPLATE })
  }
}

function* getTemplateById(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/organisation/content/email/${action.id}`, {
      organisation_id: organisation.id,
    })
    const adapted = contentAdapter.fromApi('getTemplate', data)

    yield put({
      type: contentActions.RECEIVE_GET_TEMPLATE,
      data: adapted,
    })

    if (action.resolve) {
      yield call(action.resolve, adapted)
    }
  } catch (error) {
    yield put({ type: contentActions.FAILED_GET_TEMPLATE })
  }
}

function* getWhatsonTemplates() {
  try {
    const data = yield call(api.post, `/organisation/content/emails`)

    yield put({
      type: contentActions.RECEIVE_GET_WHATSON_TEMPLATES,
      data: contentAdapter.fromApi('getTemplates', data),
    })
  } catch (error) {
    yield put({ type: contentActions.FAILED_GET_WHATSON_TEMPLATES })
  }
}

function* persistTemplate(action) {
  try {
    const organisation = getCurrentOrganisation()
    const email = contentAdapter.toApi('persistTemplate', { ...action.data })

    const emailResponse = yield call(api.post, `/organisation/content/email/upload`, {
      organisation_id: organisation.id,
      email,
    })

    yield put({ type: contentActions.RECEIVE_PERSIST_TEMPLATE })

    yield put({
      type: contentActions.REQUEST_UPDATE_ORGANISATION_TEMPLATES,
      templateId: emailResponse?.id,
    })
  } catch (error) {
    yield put({ type: contentActions.FAILED_PERSIST_TEMPLATE })
  }
}

function* deleteEmailTemplate(action) {
  try {
    const organisation = getCurrentOrganisation()

    yield call(
      api.delete,
      `/organisation/content/email/${action.data.id}?organisation_id=${organisation.id}`
    )

    yield put({ type: contentActions.REQUEST_GET_ORGANISATION_TEMPLATES })

    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: 'Email template deleted successfully 🔥',
      },
    })
  } catch (error) {
    yield put({ type: contentActions.FAILED_DELETE_EMAIL_TEMPLATE })
    yield put({
      type: NOTIFY,
      notification: {
        type: 'warning',
        message: 'Something went wrong deleting email template. Please try again later.',
      },
    })
  }
}

export default function* root() {
  yield takeLatest(contentActions.REQUEST_GET_ORGANISATION_TEMPLATES, getOrganisationTemplates)
  yield takeLatest(contentActions.REQUEST_GET_WHATSON_TEMPLATES, getWhatsonTemplates)
  yield takeLatest(contentActions.REQUEST_GET_TEMPLATE, getTemplateById)
  yield takeLatest(contentActions.REQUEST_PERSIST_TEMPLATE, persistTemplate)
  yield takeLatest(contentActions.REQUEST_UPDATE_ORGANISATION_TEMPLATES, updateOrganisationTemplate)
  yield takeLatest(contentActions.REQUEST_DELETE_EMAIL_TEMPLATE, deleteEmailTemplate)
}
