import App from 'common/ui/App'
import { hydrateRoot } from 'react-dom/client'
import React from 'react'
import { Router } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { Provider } from 'react-redux'
import { ComponentProvider } from '@akkeramsterdam/whatson-components'
// Import configs
import store from 'common/config/store'
import history from 'common/config/history'
import { initGA } from 'common/config/ga'
import { i18nextInstance as i18n } from 'config/i18next'

// Initiate Google Analytics
initGA()

function render(Root) {
  loadableReady().then(() => {
    setTimeout(() => {
      hydrateRoot(
        document.getElementById('root'),
        <Provider store={store}>
          <Router history={history}>
            <ComponentProvider i18n={i18n}>
              <Root />
            </ComponentProvider>
          </Router>
        </Provider>
      )

      // Eliminate flash
      document.getElementById('root').style.display = 'block'
    }, 250)
  })
}

if (module.hot) {
  module.hot.accept('common/ui/App', () => {
    const NewApp = require('common/ui/App').default

    return render(NewApp)
  })
}

render(App)
