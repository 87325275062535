import { fork } from 'redux-saga/effects'

// Import sagas
import campaign from './campaign'
import content from './content'
import inbox from './inbox'
import insights from './insights'
import notification from './notification'
import organisation from './organisation'
import people from './people'
import user from './user'

// Export root saga
export default function* root() {
  yield fork(campaign)
  yield fork(content)
  yield fork(inbox)
  yield fork(insights)
  yield fork(notification)
  yield fork(organisation)
  yield fork(people)
  yield fork(user)
}
