import * as actions from 'redux/actions/organisation'
import { RESET_STATE } from 'redux/actions/global'

const initialState = {
  mergeFields: {
    collection: {
      data: [],
      error: '',
      fetching: false,
    },
  },
  sender: {
    collection: {
      data: [],
      error: '',
      fetching: false,
    },
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_GET_MERGE_FIELDS:
      return {
        ...state,
        mergeFields: {
          ...state.mergeFields,
          collection: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_SENDERS:
      return {
        ...state,
        sender: {
          ...state.sender,
          collection: {
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    default:
      return state
  }
}
