export const FAILED_DELETE_USER = 'FAILED_DELETE_USER'
export const FAILED_GET_USER = 'FAILED_GET_USER'
export const FAILED_GET_USERS = 'FAILED_GET_USERS'
export const FAILED_LOGIN_USER = 'FAILED_LOGIN_USER'
export const FAILED_LOGOUT_USER = 'FAILED_LOGOUT_USER'
export const FAILED_PASSWORD_FORGOT = 'FAILED_PASSWORD_FORGOT'
export const FAILED_PASSWORD_RESET = 'FAILED_PASSWORD_RESET'
export const FAILED_PERSIST_USER = 'FAILED_PERSIST_USER'
export const FAILED_REGISTER_USER = 'FAILED_REGISTER_USER'
export const FAILED_USER_AUTH_STATUS = 'FAILED_USER_AUTH_STATUS'
export const RECEIVE_DELETE_USER = 'RECEIVE_DELETE_USER'
export const RECEIVE_GET_USER = 'RECEIVE_GET_USER'
export const RECEIVE_GET_USERS = 'RECEIVE_GET_USERS'
export const RECEIVE_LOGIN_USER = 'RECEIVE_LOGIN_USER'
export const RECEIVE_LOGOUT_USER = 'RECEIVE_LOGOUT_USER'
export const RECEIVE_PASSWORD_FORGOT = 'RECEIVE_PASSWORD_FORGOT'
export const RECEIVE_PASSWORD_RESET = 'RECEIVE_PASSWORD_RESET'
export const RECEIVE_PERSIST_USER = 'RECEIVE_PERSIST_USER'
export const RECEIVE_REGISTER_USER = 'RECEIVE_REGISTER_USER'
export const RECEIVE_USER_AUTH_STATUS = 'RECEIVE_USER_AUTH_STATUS'
export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER'
export const REQUEST_GET_USER = 'REQUEST_GET_USER'
export const REQUEST_GET_USERS = 'REQUEST_GET_USERS'
export const REQUEST_LOGIN_USER = 'REQUEST_LOGIN_USER'
export const REQUEST_LOGOUT_USER = 'REQUEST_LOGOUT_USER'
export const REQUEST_PASSWORD_FORGOT = 'REQUEST_PASSWORD_FORGOT'
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PERSIST_USER = 'REQUEST_PERSIST_USER'
export const REQUEST_REGISTER_USER = 'REQUEST_REGISTER_USER'
export const REQUEST_USER_AUTH_STATUS = 'REQUEST_USER_AUTH_STATUS'
