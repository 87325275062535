export const FAILED_GET_ORGANISATION_TEMPLATES = 'FAILED_GET_ORGANISATION_TEMPLATES'
export const FAILED_GET_TEMPLATE = 'FAILED_GET_TEMPLATE'
export const FAILED_GET_WHATSON_TEMPLATES = 'FAILED_GET_WHATSON_TEMPLATES'
export const FAILED_PERSIST_TEMPLATE = 'FAILED_PERSIST_TEMPLATE'
export const FAILED_UPDATE_ORGANISATION_TEMPLATE = 'FAILED_UPDATE_ORGANISATION_TEMPLATE'
export const FAILED_DELETE_EMAIL_TEMPLATE = 'FAILED_DELETE_EMAIL_TEMPLATE'

export const RECEIVE_GET_ORGANISATION_TEMPLATES = 'RECEIVE_GET_ORGANISATION_TEMPLATES'
export const RECEIVE_GET_TEMPLATE = 'RECEIVE_GET_TEMPLATE'
export const RECEIVE_GET_WHATSON_TEMPLATES = 'RECEIVE_GET_WHATSON_TEMPLATES'
export const RECEIVE_PERSIST_TEMPLATE = 'RECEIVE_PERSIST_TEMPLATE'
export const RECEIVE_UPDATE_ORGANISATION_TEMPLATE = 'RECEIVE_UPDATE_ORGANISATION_TEMPLATE'

export const REQUEST_GET_ORGANISATION_TEMPLATES = 'REQUEST_GET_ORGANISATION_TEMPLATES'
export const REQUEST_GET_TEMPLATE = 'REQUEST_GET_TEMPLATE'
export const REQUEST_GET_WHATSON_TEMPLATES = 'REQUEST_GET_WHATSON_TEMPLATES'
export const REQUEST_PERSIST_TEMPLATE = 'REQUEST_PERSIST_TEMPLATE'
export const REQUEST_UPDATE_ORGANISATION_TEMPLATES = 'REQUEST_UPDATE_ORGANISATION_TEMPLATES'
export const REQUEST_DELETE_EMAIL_TEMPLATE = 'REQUEST_DELETE_EMAIL_TEMPLATE'
