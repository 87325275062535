export const FAILED_DELETE_NOTIFICATION = 'FAILED_DELETE_NOTIFICATION'
export const FAILED_GET_INBOX = 'FAILED_GET_INBOX'
export const FAILED_GET_ARCHIVED_COUNT = 'FAILED_GET_ARCHIVED_COUNT'
export const FAILED_GET_UNREAD_COUNT = 'FAILED_GET_UNREAD_COUNT'
export const FAILED_UPDATE_NOTIFICATION = 'FAILED_UPDATE_NOTIFICATION'
export const RECEIVE_DELETE_NOTIFICATION = 'RECEIVE_DELETE_NOTIFICATION'
export const RECEIVE_GET_INBOX = 'RECEIVE_GET_INBOX'
export const RECEIVE_GET_ARCHIVED_COUNT = 'RECEIVE_GET_ARCHIVED_COUNT'
export const RECEIVE_GET_UNREAD_COUNT = 'RECEIVE_GET_UNREAD_COUNT'
export const RECEIVE_UPDATE_NOTIFICATION = 'RECEIVE_UPDATE_NOTIFICATION'
export const REQUEST_DELETE_NOTIFICATION = 'REQUEST_DELETE_NOTIFICATION'
export const REQUEST_GET_INBOX = 'REQUEST_GET_INBOX'
export const REQUEST_GET_ARCHIVED_COUNT = 'REQUEST_GET_ARCHIVED_COUNT'
export const REQUEST_GET_UNREAD_COUNT = 'REQUEST_GET_UNREAD_COUNT'
export const REQUEST_UPDATE_NOTIFICATION = 'REQUEST_UPDATE_NOTIFICATION'
