import {
  FAILED_GET_USER,
  FAILED_PERSIST_USER,
  FAILED_USER_AUTH_STATUS,
  FAILED_LOGIN_USER,
  FAILED_PASSWORD_FORGOT,
  FAILED_PASSWORD_RESET,
  RECEIVE_GET_USER,
  RECEIVE_GET_USERS,
  RECEIVE_USER_AUTH_STATUS,
  RECEIVE_LOGIN_USER,
  RECEIVE_PASSWORD_FORGOT,
  RECEIVE_PERSIST_USER,
  REQUEST_USER_AUTH_STATUS,
  REQUEST_LOGIN_USER,
  REQUEST_PERSIST_USER,
} from 'redux/actions/user'
import { RESET_STATE } from 'redux/actions/global'

const initialState = {
  id: null,
  roles: null,
  authenticated: false,
  authError: false,
  passwordResetRequested: false,
  checked: false,
  meta: {},
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false,
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FAILED_LOGIN_USER:
    case FAILED_PASSWORD_FORGOT:
    case FAILED_PASSWORD_RESET:
      return {
        ...state,
        authenticated: false,
        checked: true,
        authError: action.message || true,
      }
    case FAILED_USER_AUTH_STATUS:
      return {
        ...state,
        authenticated: false,
        checked: true,
      }
    case FAILED_GET_USER:
    case FAILED_PERSIST_USER:
      return {
        ...state,
        form: {
          ...state.form,
          fetching: false,
          sending: false,
          deleting: false,
        },
      }
    case RECEIVE_PERSIST_USER:
    case RECEIVE_GET_USER:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
          sending: false,
        },
      }
    case RECEIVE_GET_USERS:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
          pageNumber: action.pageNumber,
          totalCount: action.totalCount,
        },
      }
    case RECEIVE_LOGIN_USER:
      return {
        ...state,
        authenticated: true,
        checked: true,
      }
    case RECEIVE_USER_AUTH_STATUS:
      const { id, organisations, roles, ...rest } = action.user

      return {
        ...state,
        id,
        // authenticated: supportedRoles.includes(action.role),
        authenticated: true,
        checked: true,
        roles,
        meta: {
          organisations,
          ...rest,
        },
      }
    case RECEIVE_PASSWORD_FORGOT:
      return {
        ...state,
        passwordResetRequested: true,
      }
    case REQUEST_PERSIST_USER:
      return {
        ...state,
        form: {
          ...state.form,
          sending: true,
        },
      }

    case RESET_STATE:
      if (action.state === 'user') {
        return {
          ...state,
          [action.screen]: initialState[action.screen],
        }
      }

    case REQUEST_USER_AUTH_STATUS:
    case REQUEST_LOGIN_USER:
    default:
      return state
  }
}
