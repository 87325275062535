import { BaseApiAdapter } from 'services/api/adapter'
import { delayOptions, getCurrentOrganisation } from 'config/helpers'
import { adaptEmailFromApi, adaptEmailToApi } from './helpers'

const adaptTimeDelayFromApi = (seconds) => {
  const timeUnit =
    delayOptions?.toReversed()?.find((option) => seconds % option.value === 0) || delayOptions[0]

  return {
    value: seconds / timeUnit.value,
    timeUnit,
  }
}

const adaptPercentagesToApi = (percentage) => ({
  percentages: [parseInt(percentage), parseInt(100 - percentage)],
})

const adaptBlockToElement = (element, children = []) => ({
  id: element.id,
  type: element[element.type],
  variant: element.type,
  ...(children.length > 0 ? { elements: children } : {}),
  ...(element.email ? { email: adaptEmailFromApi(element.email) } : {}),
  ...(element.percentages ? { value: element.percentages[0] } : {}),
  ...(element.sender ? { senderId: element.sender.id } : {}),
  ...(element.seconds ? { ...adaptTimeDelayFromApi(element.seconds) } : {}),
  ...(element.side ? { side: element.side } : {}),
})

const adaptBlocksToElements = (block, initialBlocks) => {
  if (!block.next) {
    return [adaptBlockToElement(block)]
  } else if (Array.isArray(block.next)) {
    const children = initialBlocks
      .filter(({ id }) => block.next.includes(id))
      .map((child, index) => ({ ...child, side: ['A', 'B'][index] }))
    const adaptedChildren = [
      ...adaptBlocksToElements(children[0], initialBlocks),
      ...adaptBlocksToElements(children[1], initialBlocks),
    ]

    return [adaptBlockToElement(block, adaptedChildren)]
  } else {
    const sibling = initialBlocks.find(({ id }) => id === block.next)
    if (sibling && block.side) {
      sibling.side = block.side
    }

    return [
      adaptBlockToElement(block),
      ...(sibling ? adaptBlocksToElements(sibling, initialBlocks) : []),
    ]
  }
}

const adaptElementToBlock = (element, stripIds) => ({
  type: element.variant,
  [element.variant]: element.type,
  ...(!stripIds && !element.id.startsWith('new-id-') ? { id: element.id } : {}),
  ...(element.elements ? { blocks: element.elements } : {}),
  // ...(element.side ? { side: element.side } : {}),
  ...(element.type === 'time_delay' ? { seconds: element.value * element.timeUnit.value } : {}),
  ...(element.type === 'email'
    ? { senderId: element.senderId, email: adaptEmailToApi(element.email) }
    : {}),
  ...(element.type === 'percentage_split' ? { ...adaptPercentagesToApi(element.value) } : {}),
})

const adaptElementsToBlocks = (element, elements, stripIds) => {
  if (element.elements) {
    const elementsSideA = element.elements?.filter(({ side }) => side === 'A') || []
    const elementsSideB = element.elements?.filter(({ side }) => side === 'B') || []

    const adaptedElements = [
      ...(elementsSideA[0] ? [elementsSideA[0]] : []),
      ...(elementsSideB[0] ? [elementsSideB[0]] : []),
    ].map((elementOnSide) => {
      const elementsOnSide = element.elements.filter(({ side }) => side === elementOnSide.side)

      return adaptElementsToBlocks(elementOnSide, elementsOnSide, stripIds)[0]
    })

    if (adaptedElements) {
      element.elements = adaptedElements
    }

    return [adaptElementToBlock(element, stripIds)]
  } else {
    const elementIndex = elements.map(({ id }) => id).indexOf(element.id)
    const hasSibling = elementIndex !== -1 && elements[elementIndex + 1]

    if (hasSibling) {
      const sibling = elements[elementIndex + 1]
      element.elements = adaptElementsToBlocks(sibling, elements, stripIds)
    }

    return [adaptElementToBlock(element, stripIds)]
  }
}

const getCampaign = (campaign) => {
  if (campaign?.flow) {
    const { blocks } = campaign.flow
    const firstBlock = blocks[0]
    const elements = adaptBlocksToElements(firstBlock, blocks)

    campaign.flow.elements = elements
    campaign.flow.count = {
      emails: blocks.filter(({ type }) => type === 'action').length,
      splits: blocks.filter(({ type }) => type === 'flow').length,
      triggers: blocks.filter(({ type }) => type === 'trigger').length,
    }

    delete campaign.flow.blocks
  }

  return campaign
}

const getOutreaches = (outreaches) => {
  const organisation = getCurrentOrganisation()

  return outreaches
    .map((outreach) => this.camelifyObject(outreach))
    .map((outreach) => ({
      id: outreach.outreachId,
      body: {
        html: outreach.plain,
        plain: outreach.plain,
        preheader: outreach.plain,
      },
      sender: {
        email: outreach?.sender_email || organisation.name,
        name: outreach?.sender_name || organisation.name,
      },
      sent: new Date(outreach.timestamp),
      subject: outreach.subject,
      threadId: outreach.threadId,
    }))
}

const setCampaignFlow = ({ elements, ...flow }, stripIds = false) => ({
  ...flow,
  blocks: adaptElementsToBlocks(elements[0], elements, stripIds),
})

export class CampaignAdapter extends BaseApiAdapter {
  fromApi = (adapter, theirs) => {
    switch (adapter) {
      case 'getOutreaches':
        return getOutreaches(theirs)
      case 'getCampaign':
        return getCampaign(this.camelifyObject(theirs))
      default:
        return this.camelifyObject(theirs)
    }
  }

  toApi = (adapter, ours) => {
    switch (adapter) {
      case 'setDuplicateCampaignFlow':
        return this.snakifyObject(setCampaignFlow(ours, true))
      case 'setCampaignFlow':
        return this.snakifyObject(setCampaignFlow(ours))
      default:
        return this.snakifyObject(ours)
    }
  }
}
