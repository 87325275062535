import React, { useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Redirect, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { ROLE_ADMINISTRATOR } from 'config/user'
import { isServer } from 'config/helpers'
import { Loader, Logo, PrivateRoute, PublicRoute } from '@akkeramsterdam/whatson-components'
import styles from './Routes.module.scss'

// Loadable
const options = {
  fallback: <Loader />,
}

const CampaignFlow = loadable(() => import('ui/containers/Campaign/Flow'), options)

const CampaignInsights = loadable(() => import('ui/containers/Insights/Campaign'), options)

const CampaignOverview = loadable(() => import('ui/containers/Campaign/Overview'), options)

const CompaignView = loadable(() => import('ui/containers/Campaign/View'), options)

const ContentTemplates = loadable(() => import('ui/containers/Content/Templates'), options)

const Inbox = loadable(() => import('ui/containers/Inbox'), options)

const Login = loadable(() => import('ui/containers/Authentication/Login'), options)

const OrganisationInsights = loadable(() => import('ui/containers/Insights/Organisation'), options)

const OrganizationCreate = loadable(
  () => import('ui/containers/Organization/Create'),
  options
)

const OrganizationCreateOrJoin = loadable(
  () => import('ui/containers/Organization/CreateOrJoin'),
  options
)

const OrganizationRequestSent = loadable(
  () => import('ui/containers/Organization/RequestSent'),
  options
)

const Overview = loadable(() => import('ui/containers/Overview'), options)

const PasswordForgot = loadable(
  () => import('ui/containers/Authentication/PasswordForgot'),
  options
)

const PasswordReset = loadable(
  () => import('ui/containers/Authentication/PasswordReset'),
  options
)

const People = loadable(() => import('ui/containers/People'), options)

const SignUp = loadable(
  () => import('ui/containers/Authentication/SignUp'),
  options
)

export default ({ authenticated, checked, role, userAuthStatus, ...props }) => {
  useEffect(() => {
    userAuthStatus()
  }, [props.location])

  // Server can skip auth check (should also move this check to the private route component)
  if (typeof window !== 'undefined' && !checked) {
    return <Loader />
  }

  return (
    <Route
      render={({ location }) => {
        // Strange bug, for some reason Redux PUSH action is in location after navigating to new page
        const currentLocation = location.action ? location.location : location

        return (
          <TransitionGroup component={null}>
            <CSSTransition
              key={currentLocation.key}
              classNames={{
                enter: styles.Enter,
                enterActive: styles.EnterActive,
                exit: styles.Exit,
                exitActive: styles.ExitActive,
              }}
              timeout={300}
            >
              <Switch location={currentLocation}>
                <PublicRoute
                  authenticated={authenticated}
                  exact
                  path="/login"
                  render={() => <Login />}
                  strict
                />

                <PublicRoute
                  authenticated={authenticated}
                  exact
                  path="/password/forgot"
                  render={() => <PasswordForgot />}
                  strict
                />

                <PublicRoute
                  authenticated={authenticated}
                  exact
                  path="/password/reset"
                  render={(match) => <PasswordReset match={match} />}
                  strict
                />

                <PublicRoute
                  authenticated={authenticated}
                  exact
                  path="/signup"
                  render={() => <SignUp />}
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/"
                  render={() => <Overview />}
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/campaigns"
                  render={() => <CampaignOverview />}
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/campaigns/:id/flow"
                  render={({ match }) => <CampaignFlow id={match.params.id} />}
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/campaigns/:id/:view?"
                  render={({ match }) =>
                    <CompaignView
                      id={match.params.id}
                      view={match.params.view}
                    />
                  }
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/content/templates/:view?"
                  render={({ match }) =>
                    <ContentTemplates
                      id={match.params.id}
                      view={match.params.view}
                    />
                  }
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/inbox/:id?"
                  render={({ location, match }) =>
                    <Inbox
                      id={match.params.id}
                      location={location}
                    />
                  }
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/insights/campaigns/:id"
                  render={({ match }) => <CampaignInsights id={match.params.id} />}
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/insights"
                  render={() => <OrganisationInsights />}
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/people/:view?/:id?"
                  render={({ match }) =>
                    <People
                      id={match.params.id}
                      view={match.params.view}
                    />
                  }
                  strict
                  userRole={role}
                />

                {/* <Route component={NotFound} />*/}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )
      }}
    />
  )
}
