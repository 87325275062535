import * as contentActions from 'redux/actions/content'

const initialState = {
  media: {
    collection: {
      data: [],
      error: '',
      fetching: true,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
    },
  },
  templates: {
    organisation: {
      collection: {
        data: [],
        error: '',
        fetching: true,
      },
      member: {
        data: {},
        error: '',
        fetching: true,
      },
    },
    whatson: {
      collection: {
        data: [],
        error: '',
        fetching: true,
      },
      member: {
        data: {},
        error: '',
        fetching: true,
      },
    },
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case contentActions.RECEIVE_GET_ORGANISATION_TEMPLATES:
      return {
        ...state,
        templates: {
          ...state.templates,
          organisation: {
            ...state.templates.organisation,
            collection: {
              ...state.templates.organisation.collection,
              data: action.data,
              fetching: false,
            },
          },
        },
      }
    case contentActions.RECEIVE_GET_WHATSON_TEMPLATES:
      return {
        ...state,
        templates: {
          ...state.templates,
          whatson: {
            ...state.templates.whatson,
            collection: {
              ...state.templates.whatson.collection,
              data: action.data,
              fetching: false,
            },
          },
        },
      }
    case contentActions.RECEIVE_UPDATE_ORGANISATION_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          organisation: {
            ...state.templates.organisation,
            collection: {
              ...state.templates.organisation.collection,
              data: state.templates.organisation.collection.data.map((template) =>
                template.id === action.payload.id ? { ...template, ...action.payload } : template
              ),
              fetching: false,
            },
          },
        },
      }
    default:
      return state
  }
}
