export const FAILED_CREATE_AUDIENCE = 'FAILED_CREATE_AUDIENCE'
export const RECEIVE_CREATE_AUDIENCE = 'RECEIVE_CREATE_AUDIENCE'
export const REQUEST_CREATE_AUDIENCE = 'REQUEST_CREATE_AUDIENCE'

export const FAILED_DELETE_AUDIENCE = 'FAILED_DELETE_AUDIENCE'
export const RECEIVE_DELETE_AUDIENCE = 'RECEIVE_DELETE_AUDIENCE'
export const REQUEST_DELETE_AUDIENCE = 'REQUEST_DELETE_AUDIENCE'

export const FAILED_GET_AUDIENCE = 'FAILED_GET_AUDIENCE'
export const RECEIVE_GET_AUDIENCE = 'RECEIVE_GET_AUDIENCE'
export const REQUEST_GET_AUDIENCE = 'REQUEST_GET_AUDIENCE'

export const FAILED_GENERATE_AUDIENCE = 'FAILED_GENERATE_AUDIENCE'
export const RECEIVE_GENERATE_AUDIENCE = 'RECEIVE_GENERATE_AUDIENCE'
export const REQUEST_GENERATE_AUDIENCE = 'REQUEST_GENERATE_AUDIENCE'

export const REQUEST_DOWNLOAD_TESTBATCH = 'REQUEST_DOWNLOAD_TESTBATCH'

export const FAILED_GET_AUDIENCES = 'FAILED_GET_AUDIENCES'
export const RECEIVE_GET_AUDIENCES = 'RECEIVE_GET_AUDIENCES'
export const REQUEST_GET_AUDIENCES = 'REQUEST_GET_AUDIENCES'

export const FAILED_GET_BLOCK_LIST = 'FAILED_GET_BLOCK_LIST'
export const RECEIVE_GET_BLOCK_LIST = 'RECEIVE_GET_BLOCK_LIST'
export const REQUEST_GET_BLOCK_LIST = 'REQUEST_GET_BLOCK_LIST'

export const FAILED_GET_LEADS = 'FAILED_GET_LEADS'
export const RECEIVE_GET_LEADS = 'RECEIVE_GET_LEADS'
export const REQUEST_GET_LEADS = 'REQUEST_GET_LEADS'

export const FAILED_MOVE_PROSPECT = 'FAILED_MOVE_PROSPECT'
export const RECEIVE_MOVE_PROSPECT = 'RECEIVE_MOVE_PROSPECT'
export const REQUEST_MOVE_PROSPECT = 'REQUEST_MOVE_PROSPECT'

export const FAILED_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS =
  'FAILED_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS'
export const RECEIVE_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS =
  'RECEIVE_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS'
export const REQUEST_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS =
  'REQUEST_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS'

export const FAILED_GET_PROSPECT_SAMPLE_FILTERS = 'FAILED_GET_PROSPECT_SAMPLE_FILTERS'
export const RECEIVE_GET_PROSPECT_SAMPLE_FILTERS = 'RECEIVE_GET_PROSPECT_SAMPLE_FILTERS'
export const REQUEST_GET_PROSPECT_SAMPLE_FILTERS = 'REQUEST_GET_PROSPECT_SAMPLE_FILTERS'

export const FAILED_GET_PROSPECT_SAMPLE = 'FAILED_GET_PROSPECT_SAMPLE'
export const RECEIVE_GET_PROSPECT_SAMPLE = 'RECEIVE_GET_PROSPECT_SAMPLE'
export const REQUEST_GET_PROSPECT_SAMPLE = 'REQUEST_GET_PROSPECT_SAMPLE'

export const RECEIVE_GET_PROSPECT_SAMPLE_COUNT = 'RECEIVE_GET_PROSPECT_SAMPLE_COUNT'

export const FAILED_GET_PROSPECTS = 'FAILED_GET_PROSPECTS'
export const RECEIVE_GET_PROSPECTS = 'RECEIVE_GET_PROSPECTS'
export const REQUEST_GET_PROSPECTS = 'REQUEST_GET_PROSPECTS'
