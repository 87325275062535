import * as moment from 'moment'
import tailwindColors from 'tailwindcss/colors'
import { colors } from '@akkeramsterdam/whatson-components'
// import 'moment/locale/en-EN.js'
// moment.locale('en-EN')

export const delayOptions = [
  {
    label: 'Second(s)',
    value: 1,
  },
  {
    label: 'Minute(s)',
    value: 60,
  },
  {
    label: 'Hours(s)',
    value: 3600,
  },
  {
    label: 'Day(s)',
    value: 3600 * 24,
  },
  {
    label: 'Week(s)',
    value: 3600 * 24 * 7,
  },
]

export const getCurrentOrganisation = () => JSON.parse(localStorage.getItem('currentOrganisation'))

export const host = typeof window !== 'undefined' ? window.location.hostname : null

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export const supportedColors = { ...tailwindColors, ...colors }

export function capitalise(string = '') {
  return string && string[0].toUpperCase() + string.slice(1)
}

export function centsToEuros(cents) {
  const amount = cents / 100

  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export function formatEmailDate(date, fromNow = false) {
  if (fromNow) {
    return moment(date).fromNow()
  }

  const now = new Date()

  if (date.getYear() < now.getYear()) {
    return moment(date).format('YYYY-MM-DD')
  }

  if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth()) {
    return moment(date).format('hh:mm')
  }

  return moment(date).format('D MMMM')
}

export function getImageUrl(id) {
  const baseUrl = process.env.RAZZLE_API_URI
  const organisation = getCurrentOrganisation()

  return `${baseUrl}/organisation/${organisation.id}/content/image/${id}`
}

export function validateEmailAddress(email = '') {
  const regex =
    /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}[.]{1}){1,125}[a-zA-Z0-9]{2,63}$/

  return email && regex.test(email)
}

export function getInitials(string) {
  if (validateEmailAddress(string)) {
    const split = string.split('@')

    return `${split[0][0]}${split[1][0]}`.toUpperCase()
  }

  const matchedUpperCaseChars = string.match(/\p{Lu}/gu)
  let uppercaseChars

  if (matchedUpperCaseChars && matchedUpperCaseChars.length >= 2) {
    uppercaseChars = matchedUpperCaseChars
  } else {
    uppercaseChars = string.toUpperCase().match(/\p{Lu}/gu)
  }

  if (!uppercaseChars || uppercaseChars.length < 2) {
    return string.length >= 2 ? string.slice(0, 2).toUpperCase() : string.toUpperCase()
  }

  return `${uppercaseChars[0]}${uppercaseChars[1]}`
}

export function join(delimiter = ' ', ...args) {
  return args.join(delimiter).trim()
}

export function openUrlInNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href,
  }).click()
}

export function sluggify(string = '') {
  return (
    string &&
    [...string.toLowerCase()]
      .filter((char) => /[a-zA-Z\s-]/.test(char))
      .join('')
      .replace(/\s/g, '-')
  )
}

export function toEuros(amount) {
  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export function toFloat(input) {
  if ('string' === typeof input) {
    return parseFloat(input?.replace(',', '.'))
  }

  if ('number' === typeof input) {
    return parseFloat(input)
  }
}

export function toQueryString(params) {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
}

export function validatePassword(password = '') {
  const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&?\*])(?=.{12,})/

  return password && regex.test(password)
}
