import { getImageUrl } from 'config/helpers'

const adaptMergeFieldFromApi = (mergeField, string = '') => {
  // Replace images
  if (mergeField.fieldType ==='content' && mergeField.contentId && mergeField.inline) {
    const src = getImageUrl(mergeField.contentId)
    let imagesReplaced = string

    // Support legacy merge field format
    imagesReplaced?.replaceAll(
      `[[image:${mergeField.contentId}]]`,
      `<img src="${src}" alt="${mergeField.fallback}" data-content-id="${mergeField.contentId}">`
    )

    // New mergefield format
    imagesReplaced?.replaceAll(
      `[[mergefield:${mergeField.slugName}]]`,
      `<img src="${src}" alt="${mergeField.fallback}" data-content-id="${mergeField.contentId}">`
    )

    return imagesReplaced
  }

  // Replace mergefields
  if (mergeField.fieldType ==='text') {
    if (mergeField?.id) {
        return string?.replaceAll(
          `[[mergefield:${mergeField.id}]]`,
          `<mergefield data-id="${mergeField.id}" data-fallback="${mergeField.fallback}" data-visual-name="${mergeField.visualName}">[[${mergeField.slugName}]]</mergefield>`
        )
    } else {
        return string?.replaceAll(
            `[[mergefield:${mergeField.slugName}]]`,
            `<mergefield data-fallback="${mergeField.fallback}" data-visual-name="${mergeField.visualName}">[[${mergeField.slugName}]]</mergefield>`
        )
    }
  }

  // Add attachments
  if (mergeField.fieldType ==='content' && mergeField.contentId && !mergeField.inline) {
    return `${string}\n\n\r<mergefield data-id="${mergeField.id}" data-fallback="${mergeField.fallback}" data-visual-name="${mergeField.visualName}" data-inline="false">[[${mergeField.slugName}]]</mergefield>`
  }

  return string
}

const adaptMergeFieldToApi = (mergeField, string = '') => {
  const mergeFieldRegex = new RegExp(`<mergefield([a-z0-9\\s\\-\\=\\"\\(\\)\\\\])+>\\[\\[${mergeField.slugName}\\]\\]<\\/mergefield>`, 'gi')

  // Replace mergefields
  if (mergeField.fieldType === 'text') {
    return string?.replaceAll(mergeFieldRegex,
      `[[mergefield:${mergeField?.id ? mergeField.id : mergeField.slugName}]]`)
  }

  // Remove attachments
  if (mergeField.fieldType ==='content' && mergeField.contentId && !mergeField.inline) {
    return string?.replaceAll(mergeFieldRegex, '')
  }

  return string
}

export const adaptEmailFromApi = (email) => {
  let html = email?.html
  let subject = `<p>${email?.subject}</p>`
  let preheader = `<p>${email?.preheader}</p>`

  email.mergeFields?.forEach((mergeField) => {
    html = adaptMergeFieldFromApi(mergeField, html)
    subject = adaptMergeFieldFromApi(mergeField, subject)
    preheader = adaptMergeFieldFromApi(mergeField, preheader)
  })

  // Remove any residual mergefields and images that could not be replaced
  html = html?.replace(/\[\[image:([a-z0-9]+)\]\]/gi, '')
  html = html?.replace(/\[\[mergefield:([a-z0-9]+)\]\]/gi, '')

  return {
    ...email,
    html,
    subject,
    preheader,
  }
}

export const adaptEmailToApi = (email) => {
  const matchParagraph = new RegExp('^<p>(.*)<\/p>$', 'gi')
  let html = email?.html
  let subject = email?.subject?.replace(matchParagraph, '$1')
  let preheader = email?.preheader?.replace(matchParagraph, '$1')

  email.mergeFields?.forEach((mergeField) => {
    html = adaptMergeFieldToApi(mergeField, html)
    subject = adaptMergeFieldToApi(mergeField, subject)
    preheader = adaptMergeFieldToApi(mergeField, preheader)
  })

  email.mergeFieldCount = email?.mergeFields?.length || 0

  return {
    ...email,
    html,
    subject,
    preheader,
  }
}
