import * as actions from 'redux/actions/insights'
import { RESET_STATE } from 'redux/actions/global'
import { supportedRoles } from 'config/user'

const initialState = {
  campaign: {
    collection: {
      data: [],
      error: '',
      fetching: true,
    },
    comparison: {
      data: [],
      error: '',
      fetching: true,
    },
    email: {
      data: {},
      error: '',
      fetching: true,
    },
    general: {
      data: {},
      error: '',
      fetching: true,
    },
    member: {
      data: {},
      error: '',
      fetching: true,
    },
  },
  organisation: {
    collection: {
      data: [],
      error: '',
      fetching: true,
    },
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_GET_CAMPAIGN_EMAIL_ANALYTICS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          email: {
            ...state.campaign.email,
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_CAMPAIGN_INSIGHTS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          comparison: {
            ...state.campaign.comparison,
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          collection: {
            ...state.campaign.collection,
            data: action.data,
            totalCount: action.totalCount,
            pageNumber: action.pageNumber,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_CAMPAIGN_OVERVIEW_INSIGHTS_COUNT:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          collection: {
            ...state.campaign.collection,
            draft: action.data.draft,
            published: action.data.published,
            paused: action.data.paused,
            total: action.data.total,
            completed: action.data.completed,
            running: action.data.running,
          },
        },
      }
    case actions.RECEIVE_GET_GENERAL_ANALYTICS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          general: {
            ...state.campaign.general,
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    case actions.RECEIVE_GET_ORGANISATION_INSIGHTS:
      return {
        ...state,
        organisation: {
          ...state.organisation,
          collection: {
            ...state.organisation.collection,
            data: action.data,
            error: '',
            fetching: false,
          },
        },
      }
    default:
      return state
  }
}
