import ReactGA from 'react-ga'
import history from 'config/history'

export const initGA = () => {
  ReactGA.initialize(process.env.RAZZLE_GA_TRACKING_CODE)

  // On page hit
  ReactGA.pageview(location.pathname + location.search)

  // On route change
  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search)
  })
}

export default {
  event(event) {
    if (!window.hasOwnProperty('dataLayer')) {
      return
    }

    window.dataLayer.push(event)
  },
}
