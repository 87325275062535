import { call, cancel, delay, fork, put, takeLatest } from 'redux-saga/effects'
import api from 'services/api'
import { errorMessage, successMessage } from 'config/toast'
import { UserAdapter } from 'adapters/user'
import {
  FAILED_DELETE_USER,
  FAILED_GET_USER,
  FAILED_GET_USERS,
  FAILED_LOGIN_USER,
  FAILED_LOGOUT_USER,
  FAILED_PASSWORD_FORGOT,
  FAILED_PASSWORD_RESET,
  FAILED_REGISTER_USER,
  FAILED_USER_AUTH_STATUS,
  RECEIVE_GET_USER,
  RECEIVE_GET_USERS,
  RECEIVE_LOGOUT_USER,
  RECEIVE_PASSWORD_FORGOT,
  RECEIVE_REGISTER_USER,
  RECEIVE_USER_AUTH_STATUS,
  REQUEST_DELETE_USER,
  REQUEST_GET_USER,
  REQUEST_GET_USERS,
  REQUEST_LOGIN_USER,
  REQUEST_LOGOUT_USER,
  REQUEST_PASSWORD_FORGOT,
  REQUEST_PASSWORD_RESET,
  REQUEST_PERSIST_USER,
  REQUEST_REGISTER_USER,
  REQUEST_USER_AUTH_STATUS,
} from 'redux/actions/user'
import { NOTIFY } from 'redux/actions/notification'

const userAdapter = new UserAdapter()

let refreshTokensTask = null

function* autoRefreshTokens() {
  const minutes = 15
  yield delay(minutes * 60 * 1000)
  yield fork(authStatus)
}

function* authStatus() {
  try {
    if (refreshTokensTask) {
      cancel(refreshTokensTask)
      refreshTokensTask = null
    }

    const { user, status } = yield call(api.get, `/user/status`)

    if (status === 401) {
      throw new 'Token expired.'()
    }

    const currentOrganisation =
      localStorage.getItem('currentOrganisation') &&
      JSON.parse(localStorage.getItem('currentOrganisation'))
    const organisation = currentOrganisation
      ? user?.organisations.find(({ id }) => id === currentOrganisation.id)
      : user?.organisations?.length > 0
      ? user.organisations[0]
      : false

    if (organisation) {
      localStorage.setItem('currentOrganisation', JSON.stringify(organisation))
    }

    yield put({ type: RECEIVE_USER_AUTH_STATUS, user })

    refreshTokensTask = yield fork(autoRefreshTokens)
  } catch (error) {
    localStorage.removeItem('currentOrganisation')

    yield put({ type: FAILED_USER_AUTH_STATUS })
  }
}

function* deleteUser(action) {
  try {
    yield call(api.delete, `/user/${action.id}`)

    const notification = successMessage('')
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    const notification = errorMessage('')
    yield put({ type: FAILED_DELETE_USER, error })
  }
}

function* getUser(action) {
  try {
    const data = yield call(api.get, '/user', { id: action.id })

    yield put({ type: RECEIVE_GET_USER, data: data.user })
  } catch (error) {
    yield put({ type: FAILED_GET_USER, error })
  }
}

function* getUsers(action) {
  try {
    const data = yield call(api.get, '/user', {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_USERS,
      data: data.users.data,
      pageNumber: action.pageNumber,
      totalCount: data.users.total,
    })
  } catch (error) {
    yield put({ type: FAILED_GET_USERS, error })
  }
}

function* login(action) {
  try {
    yield call(api.post, '/user/login', userAdapter.toApi('/user/login', { ...action.data }))

    window.location.href = '/'
  } catch (error) {
    yield put({ type: FAILED_LOGIN_USER, message: error?.response?.data?.message })
  }
}

function* logout() {
  try {
    yield call(api.delete, '/user/logout')
    yield put({ type: RECEIVE_LOGOUT_USER })
    localStorage.removeItem('currentOrganisation')
    window.location.href = '/login'
  } catch (error) {
    yield put({ type: FAILED_LOGOUT_USER, error })
  }
}

function* passwordForgot(action) {
  try {
    yield call(api.post, '/user/request_password_reset', action.data)

    yield put({ type: RECEIVE_PASSWORD_FORGOT })
  } catch (error) {
    yield put({ type: FAILED_PASSWORD_FORGOT, error })
  }
}

function* passwordReset(action) {
  try {
    yield call(api.post, '/user/reset_password', {
      reset_identifier: action.token,
      password: action.password,
    })

    window.location.href = '/login'
  } catch (error) {
    yield put({ type: FAILED_PASSWORD_RESET, error })
  }
}

function* persistUser(action) {
  try {
    const data = yield call(api.put, '/user', action.data)

    yield put({ type: RECEIVE_GET_USER, data: data.user })

    // const notification = successMessage('')
    // yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_GET_USER, error })

    // const notification = errorMessage('')
    // yield put({ type: NOTIFY, notification })
  }
}

function* registerUser(action) {
  try {
    yield call(api.post, '/user/register', userAdapter.toApi('/user/register', { ...action.data }))

    yield put({ type: RECEIVE_REGISTER_USER })

    // const notification = successMessage('')
    // yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_REGISTER_USER, error })

    // const notification = errorMessage('')
    // yield put({ type: NOTIFY, notification })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_DELETE_USER, deleteUser)
  yield takeLatest(REQUEST_GET_USER, getUser)
  yield takeLatest(REQUEST_GET_USERS, getUsers)
  yield takeLatest(REQUEST_LOGIN_USER, login)
  yield takeLatest(REQUEST_LOGOUT_USER, logout)
  yield takeLatest(REQUEST_PASSWORD_FORGOT, passwordForgot)
  yield takeLatest(REQUEST_PASSWORD_RESET, passwordReset)
  yield takeLatest(REQUEST_REGISTER_USER, registerUser)
  yield takeLatest(REQUEST_PERSIST_USER, persistUser)
  yield takeLatest(REQUEST_USER_AUTH_STATUS, authStatus)
}
