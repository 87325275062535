import { i18nextInstance as i18n } from 'config/i18next'
import { capitalise } from 'config/helpers'

export const errorMessage = (message) => ({
  type: 'error',
  message,
})

export const infoMessage = (message) => ({
  type: 'info',
  message,
})

export const successMessage = (message) => ({
  type: 'success',
  message,
})

export const warningMessage = (message) => ({
  type: 'warning',
  message,
})

export const createdMeggage = (entity) => {
  const message = `${i18n.t(`${entity}:entity_one`)} ${i18n.t('notification:created')}`

  return `${capitalise(message)} ✅`
}

export const deletedMessage = (entity) => {
  const message = `${i18n.t(`${entity}:entity_one`)} ${i18n.t('notification:deleted')}`

  return `${capitalise(message)} 🗑`
}

export const savedMessage = (entity) => {
  const message = `${i18n.t(`${entity}:entity_one`)} ${i18n.t('notification:saved')}`

  return `${capitalise(message)} 💾`
}
