import { call, takeLatest, put } from 'redux-saga/effects'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { NOTIFY } from 'redux/actions/notification'
import { toast as toastComponents } from '@akkeramsterdam/whatson-components'
import styles from 'assets/Toast.module.scss'

const { Toast } = toastComponents

function* notify(action) {
  try {
    yield call(() => {
      switch (action?.notification?.type) {
        case 'error':
        case 'info':
        case 'success':
        case 'warning':
          const title = {
            error: 'Error',
            info: 'Info',
            success: 'Success',
            warning: 'Warning',
          }
          
          return toast(
            <Toast
              variant={action?.notification?.type}
              title={`${title[action?.notification?.type]}!`}
              description={action.notification.message}
            />
          )
          break
        default:
          return toast(action.notification.message)
          break
      }
    })
  } catch (error) {
    console.error(error)
  }
}

export default function* root() {
  yield takeLatest(NOTIFY, notify)
}
