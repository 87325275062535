import axios from 'axios'
import url from 'url'
import store from 'config/store'
import { ApiError } from './errors'

axios.defaults.headers.common['Accept'] = 'application/json';

// Not safe for production. Local storage vulnerable to XSS attacks
const jwtInsecureLocalStorage = process.env.RAZZLE_API_JWT_INSECURE_LOCAL_STORAGE === 'true'

const getUrl = (path) =>
  url.parse(path, true).host
    ? decodeURIComponent(path)
    : `${process.env.RAZZLE_API_URI || 'http://127.0.0.1:8080'}${decodeURIComponent(path)}`

axios.interceptors.request.use(function (req) {
  const token = localStorage.getItem('whatson_bearer_token')

  if (jwtInsecureLocalStorage && token) {
    req.headers['Authorization'] = `Bearer ${token}`
  }

  if (token && req.url.includes('/user/logout')) {
    localStorage.removeItem('whatson_bearer_token')
  }

  return req
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (res) {
  const token = res?.data?.access_token

  if (jwtInsecureLocalStorage && token) {
    localStorage.setItem('whatson_bearer_token', token)
  }

  return res
}, function (error) {
  console.log(error)

  return Promise.reject(error)
})

const executeApiCall = (method, path, data, params) => {
  const endpoint = getUrl(path)

  return axios(endpoint, {
    method,
    data,
    paramsSerializer: (params) =>
      Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&'),
    withCredentials: !jwtInsecureLocalStorage,
    xsrfCookieName: 'csrf_access_token',
    xsrfHeaderName: 'X-CSRF-TOKEN',
    ...params,
  })
    .then((response) => response.data)
    .catch((error) => ApiError.handleError(error))
}

export default {
  delete: (path, data, params = {}) => executeApiCall('DELETE', path, data, params),
  get: (path, params = {}) => executeApiCall('GET', path, null, params),
  post: (path, data, params = {}) => executeApiCall('POST', path, data, params),
  put: (path, data, params = {}) => executeApiCall('PUT', path, data, params),
}
