import { call, select, takeLatest, put } from 'redux-saga/effects'
import api from 'services/api'
import { OrganisationAdapter } from 'adapters/organisation'
import * as actions from 'redux/actions/organisation'
import * as peopleActions from 'redux/actions/people'
import { getCurrentOrganisation } from 'config/helpers'
import { NOTIFY } from 'redux/actions/notification'

const organisationAdapter = new OrganisationAdapter()

function* getMergeFields(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.get, `/organisation/${organisation.id}/content/merge_fields`)
    const adapted = data.map((field) => organisationAdapter.fromApi('', field))

    yield put({
      type: actions.RECEIVE_GET_MERGE_FIELDS,
      data: adapted,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_MERGE_FIELDS })
  }
}

function* getSenders(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.get, `/organisation/${organisation.id}/senders`)

    yield put({ type: actions.RECEIVE_GET_SENDERS, data })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_SENDERS })
  }
}

function* uploadImage(action) {
  try {
    const organisation = getCurrentOrganisation()

    const data = yield call(api.post, `/organisation/content/image/upload`, {
      organisation_id: organisation.id,
      image: action.base64String,
      image_type: action.mimeType,
    })

    yield put({ type: actions.RECEIVE_UPLOAD_IMAGE })

    if (action.resolve) {
      yield call(action.resolve, data)
    }
  } catch (error) {
    yield put({ type: actions.FAILED_UPLOAD_IMAGE })
  }
}

function* sendTemplateTestEmail(action) {
  try {
    const organisation = getCurrentOrganisation()

    const user = yield select((state) => state.user.meta)

    const data = yield call(
      api.get,
      `/campaign/test_email?content_id=${action.contentId}&organisation_id=${
        organisation.id
      }&sender_id=${action.senderId || ''}`
    )

    yield put({ type: actions.RECEIVE_SEND_TEST_EMAIL })
    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: `Your test email has been sent to ${user.email}`,
      },
    })

    if (action.resolve) {
      yield call(action.resolve, data)
    }
  } catch (error) {
    yield put({ type: actions.FAILED_SEND_TEST_EMAIL })
  }
}

function* uploadBlockListCsv(action) {
  try {
    const organisation = getCurrentOrganisation()

    yield call(api.post, `/organisation/${organisation.id}/blocklist`, {
      data: action.data,
    })

    yield put({ type: actions.RECEIVE_UPLOAD_BLOCKLIST_CSV })
    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: 'Successfully imported CSV file!',
      },
    })

    yield put({ type: peopleActions.REQUEST_GET_BLOCK_LIST })

    action.cb()
  } catch (error) {
    yield put({ type: actions.FAILED_UPLOAD_BLOCKLIST_CSV })
    yield put({
      type: NOTIFY,
      notification: {
        type: 'warning',
        message: 'Something went wrong. Could not import CSV file.',
      },
    })
  }
}

export default function* root() {
  yield takeLatest(actions.REQUEST_GET_MERGE_FIELDS, getMergeFields)
  yield takeLatest(actions.REQUEST_GET_SENDERS, getSenders)
  yield takeLatest(actions.REQUEST_UPLOAD_IMAGE, uploadImage)
  yield takeLatest(actions.REQUEST_SEND_TEST_EMAIL, sendTemplateTestEmail)
  yield takeLatest(actions.REQUEST_UPLOAD_BLOCKLIST_CSV, uploadBlockListCsv)
}
