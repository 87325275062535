import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Badge, Button, Icon, Loader, typography } from '@akkeramsterdam/whatson-components'
import Input from 'ui/components/InputAddon'
import styles from './AutomationTemplate.module.scss'
const { H3, P } = typography

const tagFilterOptions = [
  'All',
  'Re-engaging',
  'Opening',
  'Sales',
  'Prospecting',
]

const CardContent = ({ description, title }) => (
  <div className={styles.CardContent}>
    <H3>{title}</H3>

    <P>{description}</P>
  </div>
)

export const START_BLANK = 'blank'

export default ({
  flows,
  state,
  setState = () => {},
  setIsValid = () => {},
}) => {
  const initialFilters = {
    search: '',
    tag: 'All'
  }
  const [filters, setFilters] = useState(initialFilters)

  const setFiltersImmutable = (update) => setFilters((current) => ({
    ...current,
    ...update,
  }))

  useEffect(() => () => setFilters(initialFilters), [])

  useEffect(() => {
    if (state.template) {
      setIsValid(true)
    }
  }, [setIsValid, state])

  if (flows.fetching) {
    return <Loader />
  }

  const filteredFlows = flows?.data
    ?.filter((template) => {
      const filter = filters.search

      return filter
        ? template.name.includes(filter)
        : true
    })
    ?.filter((template) => {
      const filter = filters.tag

      return filter && filters.tag !== 'All'
        ? template?.tags && template?.tags?.length > 0 && template?.tags?.includes(filter)
        : true
    })

  const filtersApplied = (filters.tag && filters.tag !== 'All') || filters.search

  return (
    <>
      <div className={styles.Filters}>
        <Input
          addon={<Icon name="HiMagnifyingGlass" />}
          className={styles.SearchInput}
          wrapperClassname={styles.SearchInputWrapper}
          placeholder={'Search by theme, keyword'}
          onChange={(e) => setFiltersImmutable({ search: e.target.value })}
          value={filters?.search}
        />

        <div className={styles.TagFilters}>
          {tagFilterOptions.map((tag) => (
          <Button
            onClick={() => setFiltersImmutable({ tag })}
            size="sm"
            variant={tag === filters.tag ? "pink" : "outline"}
          >
            {tag}
          </Button>
          ))}
        </div>
      </div>

      <div className={styles.ScrollContainer}>
        <div className={styles.Results}>
          {filtersApplied && filteredFlows.length === 0 &&
          <P className={styles.NoTemplates}>Your filters did not return any results</P>
          }

          {!filtersApplied && filteredFlows.length === 0 &&
          <P className={styles.NoTemplates}>No templates available at this time</P>
          }

          {!filtersApplied &&
          <div
            className={classNames(styles.Blank, {
              [styles.Active]: state.template === START_BLANK
            })}
            onClick={() => setState({ template: state.template === START_BLANK ? '' : START_BLANK })}
          >
            <Icon name="HiPlusCircle" size="3xl" />

            <CardContent
              title="Start from scratch"
              description="Want to explore and structure your own flow? Already a pro? Start with a fresh workflow."
            />
          </div>
          }

          {filteredFlows.map((template, index) => (
          <div
            className={classNames(styles.Template, {
              [styles.Active]: state.template?.name === template.name
            })}
            key={index}
            onClick={() => setState({
              template: state.template?.name === template.name
                ? ''
                : template
              })}
          >
            <CardContent
              title={template.name}
              description={template.description || ''}
            />

            <div className={styles.Tags}>
              {template?.tags?.map((tag) => <Badge key={tag}>{tag}</Badge>)}
            </div>
          </div>
          ))}
        </div>
      </div>
    </>
  )
}
