import { ApiAdapterInterface } from './types'
import { camelCase, snakeCase } from 'change-case'

export class BaseApiAdapter implements ApiAdapterInterface {
  camelifyObject = (data) => Object.entries(data)
    .reduce((carry, [key, value]) => ({
      ...carry,
      [camelCase(key)]: Array.isArray(value)
        ? value.map((entry) => !['string', 'number'].includes(typeof entry)
          ? this.camelifyObject(entry)
          : entry
        )
        : typeof value === 'object' && value !== null
          ? this.camelifyObject(value)
          : value
    }), {})

  snakifyObject = (data) => Object.entries(data)
    .reduce((carry, [key, value]) => ({
      ...carry,
      [snakeCase(key)]: Array.isArray(value)
        ? value.map((entry) => !['string', 'number'].includes(typeof entry)
          ? this.snakifyObject(entry)
          : entry
        )
        : typeof value === 'object' && value !== null
          ? this.snakifyObject(value)
          : value
    }), {})

  fromApi(adapter, theirs) {
    switch (adapter) {
      default:
        return Array.isArray(theirs)
          ? theirs.map(this.camelifyObject)
          : this.camelifyObject(theirs)
    }
  }

  toApi(adapter, ours) {
    switch (adapter) {
      default:
      return Array.isArray(ours)
        ? theirs.map(this.snakifyObject)
        : this.snakifyObject(ours)
    }
  }
}
