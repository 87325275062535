import { BaseApiAdapter } from 'services/api/adapter'

export class InboxAdapter extends BaseApiAdapter {
  fromApi = (path, apiInbox) => apiInbox
    .map((email) => this.camelifyObject(email))
    .map(({ data, ...rest }) => ({
      ...rest,
      timestamp: new Date(rest.timestamp),
      received: new Date(data.received),
      body: {
        html: data.html,
        plain: data.plain,
        preheader: data.preheader,
      },
      sender: {
        name: data.name,
        email: data.email
      },
      subject: data.subject,
      tags: data?.tags || [],
      threadId: data.threadId,
    }))
}
