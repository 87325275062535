import { call, delay, takeLatest, put } from 'redux-saga/effects'
import store from 'config/store'
import api from 'services/api'
import { InboxAdapter } from 'adapters/inbox'
import * as actions from 'redux/actions/inbox'
import * as campaignActions from 'redux/actions/campaign'
import { getCurrentOrganisation } from 'config/helpers'

const inboxAdapter = new InboxAdapter()

function* deleteNotification(action) {
  try {
    const organisation = getCurrentOrganisation()
    yield call(api.delete, `/organisation/${organisation.id}/inbox/${action.id}`)

    yield put({ type: actions.RECEIVE_DELETE_NOTIFICATION })
  } catch (error) {
    yield put({ type: actions.FAILED_DELETE_NOTIFICATION })
  }
}

function* getArchivedCount(action) {
  try {
    const isFilteredByCampaign = action?.campaignId

    const organisation = getCurrentOrganisation()
    const { count } = yield call(api.get, `/organisation/${organisation.id}/inbox/archived`, {
      params: {
        ...(isFilteredByCampaign ? { campaign_id: action.campaignId } : {}),
      },
    })

    yield put({
      type: isFilteredByCampaign
        ? campaignActions.RECEIVE_GET_ARCHIVED_RESPONSES_COUNT
        : actions.RECEIVE_GET_ARCHIVED_COUNT,
      count
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_ARCHIVED_COUNT })
  }
}

function* getNotifications(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/organisation/inbox/notifications`, {
      organisation_id: organisation?.id,
      archived: action.archived,
    })

    const adapted = inboxAdapter.fromApi('', data)

    yield put({
      type: actions.RECEIVE_GET_INBOX,
      data: adapted
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_INBOX })
  }
}

function* getUnreadCount(action) {
  try {
    const isFilteredByCampaign = action?.campaignId
    const organisation = getCurrentOrganisation()
    const { count } = yield call(api.get, `/organisation/${organisation.id}/inbox/unread`, {
      params: {
        ...(isFilteredByCampaign ? { campaign_id: action.campaignId } : {}),
      },
    })

    yield put({
      type: isFilteredByCampaign
        ? campaignActions.RECEIVE_GET_UNREAD_RESPONSES_COUNT
        : actions.RECEIVE_GET_UNREAD_COUNT,
      count
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_UNREAD_COUNT })
  }
}

function* updateNotification(action) {
  try {
    const organisation = getCurrentOrganisation()
    yield call(api.put, `/organisation/${organisation.id}/inbox/${action.id}`, action.data)
    yield put({ type: actions.RECEIVE_UPDATE_NOTIFICATION })

    if (action.inboxParams) {
      yield put({
        type: actions.REQUEST_GET_INBOX,
        ...action.inboxParams,
      })
    }

    yield put({ type: actions.REQUEST_GET_ARCHIVED_COUNT })
    yield put({ type: actions.REQUEST_GET_UNREAD_COUNT })
  } catch (error) {
    yield put({ type: actions.FAILED_UPDATE_NOTIFICATION })
  }
}

export default function* root() {
  yield takeLatest(actions.REQUEST_DELETE_NOTIFICATION, deleteNotification)
  yield takeLatest(actions.REQUEST_GET_INBOX, getNotifications)
  yield takeLatest(actions.REQUEST_GET_ARCHIVED_COUNT, getArchivedCount)
  yield takeLatest(actions.REQUEST_GET_UNREAD_COUNT, getUnreadCount)
  yield takeLatest(actions.REQUEST_UPDATE_NOTIFICATION, updateNotification)
}
