import { combineReducers } from 'redux'
import { isServer } from 'config/helpers'
import { routerReducer as router } from 'react-router-redux'
import campaign from './campaign'
import content from './content'
import inbox from './inbox'
import insights from './insights'
import organisation from './organisation'
import people from './people'
import user from './user'

const appReducer = combineReducers({
  preloaded: () => !isServer,
  campaign,
  content,
  inbox,
  insights,
  organisation,
  people,
  user,
})

export default function (state = {}, action = {}) {
  return appReducer(state, action)
}
