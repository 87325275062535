import i18next from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import { isServer } from 'config/helpers'
// Enable once i18n needed
// import LanguageDetector from 'i18next-browser-languagedetector'
import locales from 'locales'

// Must match directories under src/common/locales
export const supportedLanguages = {
  en: 'English',
  nl: 'Nederlands',
}

// Must match directories under src/common/locales/{language}
export const namespaces = ['Login', 'Password', 'Signup']

// Immediately invoked
export const i18nextInstance = (() => {
  const options = {
    fallbackLng: 'en',
    supportedLngs: Object.keys(supportedLanguages),
    ns: namespaces,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: locales(Object.keys(supportedLanguages), namespaces),
    useSuspense: !isServer,
  }

  i18next.use(initReactI18next)

  // Enable once i18n needed
  // If browser, detect language
  // if (!isServer) {
  //   i18next.use(LanguageDetector)
  // }

  // Initiate i18next
  i18next.init(options, (error) => {
    if (error) {
      console.log('i18next error', error)
    }
  })

  return i18next
})()

export { I18nextProvider } from 'react-i18next'
